<template>
  <div class="container space-y-16 font-montserrat">
    <main class="wrap">
     
    </main>
  </div>
</template>

<script>
export default {};
</script>
