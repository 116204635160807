<template>
  <div>
    <the-app-bar />
    <service/>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/footer.vue";
import TheAppBar from "../../components/NavBar/TheAppBar.vue";
import Service from '../../components/service/service.vue';
export default {
  components: { TheAppBar, Footer, Service },
};
</script>

<style scoped></style>
